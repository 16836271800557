import { readNonZeroNaturalNumber } from "@outschool/environment";
import { OsEnvType } from "@outschool/platform";
import getConfig from "next/config";

const { publicRuntimeConfig = {}, serverRuntimeConfig = {} } = getConfig();

export const GIT_COMMIT = publicRuntimeConfig.GIT_COMMIT;
export const APP_HOSTNAME = publicRuntimeConfig.APP_HOSTNAME;
export const LEARNER_HOSTNAME = publicRuntimeConfig.LEARNER_HOSTNAME;
export const OUTSCHOOL_ENV = publicRuntimeConfig.OUTSCHOOL_ENV ?? "development";
export const OS_APP_ENV = publicRuntimeConfig.OS_APP_ENV;
export const isProduction = OUTSCHOOL_ENV === "production";
export const isStaging = OUTSCHOOL_ENV === "staging";
export const isTest = OUTSCHOOL_ENV === "test";
export const isDevelopment = OUTSCHOOL_ENV === "development";
export const OS_ENV_TYPE: OsEnvType =
  publicRuntimeConfig.OS_ENV_TYPE ??
  (isProduction ? "prod" : isTest ? "test" : "dev");
export const isReviewApp = publicRuntimeConfig.IS_REVIEW_APP;
export const isBrowser = typeof window !== undefined;
export const IS_GITLAB_CI = publicRuntimeConfig.IS_GITLAB_CI;

export const OUTSCHOOL_API_URL = Boolean(publicRuntimeConfig.OUTSCHOOL_API_URL)
  ? publicRuntimeConfig.OUTSCHOOL_API_URL
  : isProduction
  ? "https://outschool.com"
  : isReviewApp
  ? "https://master-website.review.outschool.dev"
  : isStaging
  ? "https://staging.outschool.dev"
  : "http://localhost:3000";

export const CF_ACCESS_CLIENT_ID = serverRuntimeConfig.CF_ACCESS_CLIENT_ID;
export const CF_ACCESS_CLIENT_SECRET =
  serverRuntimeConfig.CF_ACCESS_CLIENT_SECRET;

export const IPLOOKUP_API_BASE_URL =
  publicRuntimeConfig.IPLOOKUP_API_BASE_URL ??
  (isProduction
    ? "iplookup.app.outschool.com"
    : isDevelopment || isTest
    ? null
    : "master-iplookup.review.outschool.dev");
export const IPLOOKUP_API_KEY = publicRuntimeConfig.IPLOOKUP_API_KEY;
export const FACEBOOK_APP_ID = publicRuntimeConfig.FACEBOOK_APP_ID;
export const GOOGLE_CLIENT_ID = publicRuntimeConfig.GOOGLE_CLIENT_ID;
export const SEGMENT_WRITE_KEY = publicRuntimeConfig.SEGMENT_WRITE_KEY;
export const SENTRY_DSN = publicRuntimeConfig.SENTRY_DSN;
export const SENTRY_ENABLED =
  publicRuntimeConfig.SENTRY_ENABLED === "true" || isProduction;
export const FEATURE_FLAGS: { [k in string]: string | boolean } = {
  COOKIE_CONSENT: publicRuntimeConfig.FF_COOKIE_CONSENT === "enabled",
};

export const EXPERIMENTS_SERVICE_URL =
  publicRuntimeConfig.EXPERIMENTS_SERVICE_URL ??
  "https://master-experiments.review.outschool.dev";

export const EXPERIMENTS_API_KEY =
  publicRuntimeConfig.EXPERIMENTS_API_KEY ?? "experiments-api-key";

const experimentAssignmentsMaxReqTimeMs = readNonZeroNaturalNumber(
  process.env.EXPERIMENT_ASSIGNMENTS_MAX_REQ_TIME_MS
);
export const EXPERIMENT_ASSIGNMENTS_MAX_REQ_TIME_MS =
  experimentAssignmentsMaxReqTimeMs ?? 500;

export const APPLE_SIGNIN_CLIENT_ID =
  publicRuntimeConfig.APPLE_SIGNIN_CLIENT_ID;

export const TRANSLATIONS_ENABLED = publicRuntimeConfig.TRANSLATIONS_ENABLED;

export const AUTO_SCHEDULED_SECTIONS_ENABLED =
  publicRuntimeConfig.FF_AUTO_SCHEDULED_SECTIONS === "enabled";

export const UI_ANALYTICS_DEBUG =
  publicRuntimeConfig.UI_ANALYTICS_DEBUG === "enabled";
export const UI_ANALYTICS_DEV_MODE = isProduction
  ? undefined
  : publicRuntimeConfig.UI_ANALYTICS_DEV_MODE;
export const UI_ANALYTICS_DISABLED_INTEGRATION_LIST =
  publicRuntimeConfig.UI_ANALYTICS_DISABLED_INTEGRATION_LIST;
export const UI_ANALYTICS_EVENT_QUEUE_LIMIT =
  publicRuntimeConfig.UI_ANALYTICS_EVENT_QUEUE_LIMIT;
export const UI_ANALYTICS_LOAD_TIME_LIMIT =
  publicRuntimeConfig.UI_ANALYTICS_LOAD_TIME_LIMIT;

export const KAFKA_REST_PROXY = isProduction
  ? "https://events.outschool.com"
  : "https://kafka-rest-proxy-stage.outschool.dev";

export const FF_USE_GOOGLE_AUTH_V2 =
  publicRuntimeConfig.FF_USE_GOOGLE_AUTH_V2 === "enabled";

export const IS_READ_ONLY_MODE =
  publicRuntimeConfig.READ_ONLY_MODE === "enabled";
