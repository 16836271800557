import { CurrencyCode } from "@outschool/gql-backend-generated";
import { useLookupIP } from "@outschool/iplookup-client";
import { useLocalStorageState } from "@outschool/local-storage";
import {
  COUNTRIES_INFO,
  CountryCode,
  CurrencyLocalizationContext,
  I18nLocale,
  ISOCountryCodeKey,
  LocaleProvider,
  SELECTED_CURRENCY_CODE_LOCAL_STORAGE_KEY,
  SetCurrencyLocalizationContext,
  getCountryFromCurrency,
  getCurrencyFromCountryIsoCode,
  getSuggestedCurrencyCode,
  useLocale,
} from "@outschool/localization";
import { useSession } from "@outschool/ui-auth";
import { useGlobalCurrencyExperiment } from "@outschool/ui-components-website";
import { useIsBot } from "@outschool/ui-utils";
import React from "react";

const CurrencyProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const locale = useLocale();
  const { isLoggedIn } = useSession();
  const isBot = useIsBot();
  const { isWaitingForAssignment, isTreatment, trigger } =
    useGlobalCurrencyExperiment();

  const { ipInfo, ipInfoLoaded } = useLookupIP();
  // ISOCountryCodeKey is the type for the COUNTRY_CODE_TO_CURRENCY_CODE which is used specifically
  // for setting default currency based on IP for the GlobalDefaultCurrencyV3 experiment
  const ipIsoCodeForGlobalCurrency = ipInfo?.country
    ?.isoCode as ISOCountryCodeKey;

  const [selectedCurrencyLocalStorage, setSelectedCurrencyLocalStorage] =
    useLocalStorageState<CurrencyCode | null>(
      SELECTED_CURRENCY_CODE_LOCAL_STORAGE_KEY,
      null
    );

  React.useEffect(() => {
    // If selectedCurrencyLocalStorage has already been set, exit because no action is needed
    if (selectedCurrencyLocalStorage) {
      return;
    }
    // Check if user is in target audience for GlobalDefaultCurrencyV3 experiment:
    // user is logged out, not a bot, and locale is English or Spanish
    if (
      !isLoggedIn &&
      !isBot &&
      (locale === I18nLocale.En || locale === I18nLocale.Es)
    ) {
      if (!ipInfoLoaded) {
        // If ipInfo has not loaded yet, wait for ipInfo to load
        return;
      }
      if (ipIsoCodeForGlobalCurrency) {
        if (isWaitingForAssignment) {
          // Do not trigger experiment if the experiment service has not assigned a variant yet
          // Wait for the experiment service to load
          return;
        }
        // We have the user's ISO country code from their IP, so trigger experiment
        trigger();
        // Set default currency using IP for treatment or locale for control
        setSelectedCurrencyLocalStorage(
          getSuggestedCurrencyCode(
            locale,
            ipIsoCodeForGlobalCurrency,
            isTreatment
          )
        );
      } else {
        // ipInfoLoaded is true but ipIsoCodeForGlobalCurrency is null, meaning there was an error/IP request timeout
        // Do not trigger the experiment and set the default currency using locale instead of IP
        setSelectedCurrencyLocalStorage(
          getSuggestedCurrencyCode(locale, null, false)
        );
      }
    } else {
      // User is NOT in target audience for this experiment
      // Do not trigger the experiment and set the default currency using locale instead of IP
      setSelectedCurrencyLocalStorage(
        getSuggestedCurrencyCode(locale, null, false)
      );
    }
  }, [
    selectedCurrencyLocalStorage,
    setSelectedCurrencyLocalStorage,
    isLoggedIn,
    isBot,
    locale,
    ipInfoLoaded,
    ipIsoCodeForGlobalCurrency,
    isWaitingForAssignment,
    trigger,
    isTreatment,
  ]);

  const setCurrency = React.useCallback(
    async (newCurrencyCode: CurrencyCode) => {
      try {
        setSelectedCurrencyLocalStorage(newCurrencyCode);
      } catch (err) {
        console.error(err);
      }
    },
    [setSelectedCurrencyLocalStorage]
  );

  // Get user's country from the currency set in local storage or default to US.
  const countryCode: CountryCode =
    getCountryFromCurrency(selectedCurrencyLocalStorage) || CountryCode.US;
  const selectedCountryInfo = COUNTRIES_INFO[countryCode];
  const currencyCode = getCurrencyFromCountryIsoCode(countryCode, false);

  return (
    <SetCurrencyLocalizationContext.Provider value={{ setCurrency }}>
      <CurrencyLocalizationContext.Provider
        value={{
          currencyCode,
          countryCode,
          selectedCurrencyCountry: selectedCountryInfo,
          isLoading: false,
          hasLoaded: true,
        }}
      >
        {children}
      </CurrencyLocalizationContext.Provider>
    </SetCurrencyLocalizationContext.Provider>
  );
};

export const LocalizationProvider = React.memo(
  ({
    children,
    userSelectedLocale,
  }: React.PropsWithChildren<{ userSelectedLocale?: I18nLocale }>) => (
    <LocaleProvider userSelectedLocale={userSelectedLocale}>
      <CurrencyProvider>{children}</CurrencyProvider>
    </LocaleProvider>
  )
);
