// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import "normalize.css"; // THIS MUST REMAIN FIRST
import "@/src/pages/globals.css";
import "react-spring-bottom-sheet/dist/style.css";

import {
  EXPERIMENTS_API_KEY,
  EXPERIMENTS_SERVICE_URL,
  EXPERIMENT_ASSIGNMENTS_MAX_REQ_TIME_MS,
  GIT_COMMIT,
  IPLOOKUP_API_BASE_URL,
  IPLOOKUP_API_KEY,
  KAFKA_REST_PROXY,
  OUTSCHOOL_ENV,
  TRANSLATIONS_ENABLED,
  isDevelopment,
  isProduction,
  isTest,
} from "@/src/Env";
import { initAnalyticsPackage } from "@/src/lib/analytics";
import { initRoutesPackage } from "@/src/lib/routes";
import usePageInfo from "@/src/lib/usePageInfo";
import ExperimentOverrideContainer, {
  useExperimentOverridesEnabled,
} from "@/src/website/client/components/ExperimentOverride";
import Link from "@/src/website/client/components/Link";
import { AttributionProvider } from "@/src/website/client/providers/AttributionProvider";
import { Theme } from "@outschool/backpack";
import {
  EVENT_SOURCE,
  init as initExperiments,
} from "@outschool/experiments-shared";
import { LookupIPProvider } from "@outschool/iplookup-client";
import {
  I18nLocale,
  TranslationsProvider,
  createUseMachineTranslationHook,
  createUseTranslationHook,
  defaultInterpolate,
} from "@outschool/localization";
import { Component as OwnershipAreaComponent } from "@outschool/ownership-areas";
import { dayjs } from "@outschool/time";
import {
  AnalyticsProvider,
  PageContextProvider,
  isBotRequest,
  measurePerformance,
  useAnalytics,
  useIdentify,
} from "@outschool/ui-analytics";
import {
  LoginFlowProvider,
  SessionProvider,
  TokenProvider,
} from "@outschool/ui-auth";
import { VideoPlayerProvider } from "@outschool/ui-components-shared";
import {
  HeadComponent,
  MobileSearchBarProvider,
  PageAlertsProvider,
  TopNoticeProvider,
  useTopNoticeContext,
} from "@outschool/ui-components-website";
import { ExperimentsServiceProvider } from "@outschool/ui-experiments";
import { LegacyThemeProvider } from "@outschool/ui-legacy-component-library";
import {
  IsBotProvider,
  NavigationProvider,
  useIsMounted,
} from "@outschool/ui-utils";
import { I18nContext, TFunction, appWithTranslation } from "next-i18next";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";

import nextI18nextConfig from "../../next-i18next.config";
import SignupWithGoogleOneTap from "../components/SignUpWithGoogleOneTap";
import { SsrApolloProvider } from "../lib/Client";
import { getDayjsLocale } from "../lib/dayjsLocale";
import SsrImage from "../website/client/components/lib/SsrImage";
import VideoPlayer from "../website/client/components/VideoPlayer";
import { useSSRNavigation } from "../website/client/hooks/useSSRNavigation";
import { LocalizationProvider } from "../website/client/providers/LocalizationProvider";

import type { NextWebVitalsMetric } from "next/app";

interface WindowWithIntercom extends Window {
  Intercom: (cmd: string, options: { [key: string]: unknown }) => void;
}

declare const window: WindowWithIntercom;

initAnalyticsPackage();
initRoutesPackage();
initExperiments({
  GIT_COMMIT_SHA: GIT_COMMIT,
  KAFKA_REST_PROXY,
  OUTSCHOOL_ENV,
});

const useIsTranslating = () => TRANSLATIONS_ENABLED;

const useTranslation = TRANSLATIONS_ENABLED
  ? createUseTranslationHook(useIsTranslating)
  : (_params: any) => ({
      t: defaultInterpolate as TFunction,
      i18n: null,
      ready: true,
      0: defaultInterpolate as TFunction,
    });

function App({ Component, pageProps }) {
  const pageInfo = usePageInfo();
  const [isBot] = React.useState(() => isBotRequest());

  return (
    <PageContextProvider pageInfo={pageInfo}>
      <LookupIPProvider
        iplookupApiBaseUrl={IPLOOKUP_API_BASE_URL}
        iplookupApiKey={IPLOOKUP_API_KEY}
      >
        <IsBotProvider value={isBot}>
          <AnalyticsProvider>
            <AppWithTrackingProvider
              Component={Component}
              pageProps={pageProps}
            />
          </AnalyticsProvider>
        </IsBotProvider>
      </LookupIPProvider>
    </PageContextProvider>
  );
}

function AppWithTopNoticeProvider({ Component, pageProps }) {
  const { setTopNotice } = useTopNoticeContext();
  const mounted = useIsMounted();
  const i18n = React.useContext(I18nContext)?.i18n;

  const useMachineTranslation = React.useMemo(() => {
    return createUseMachineTranslationHook({
      useIsTranslating: () => TRANSLATIONS_ENABLED,
      onTranslated: () => setTopNotice(""),
      onError: ({ error, translatedErrorMessage }) => {
        setTopNotice(translatedErrorMessage);
        OsPlatform.captureError(error, {
          tags: {
            component: OwnershipAreaComponent.International,
            isGoogleTranslate: true,
          },
        });
      },
    });
  }, [setTopNotice]);

  return (
    <TranslationsProvider
      i18n={i18n}
      useTranslation={useTranslation}
      useMachineTranslation={useMachineTranslation}
    >
      <SessionProvider>
        <HeadComponent as={Head} favIconPath={"/_ssr/favicon.ico"} />
        <Component {...pageProps} />
        {mounted && <ExperimentOverrideContainer />}
        {mounted && <SignupWithGoogleOneTap />}
      </SessionProvider>
    </TranslationsProvider>
  );
}

// ComponentTrackingProvider requires AnalyticsProvider to exist in the top level context
function AppWithTrackingProvider({ Component, pageProps }) {
  const identify = useIdentify();
  const { pending: analyticsPending } = useAnalytics();
  const [identified, setIdentified] = React.useState(false);
  const router = useRouter();
  const locale = router.locale as I18nLocale;

  const isExperimentOverridesEnabled = useExperimentOverridesEnabled();
  const { experimentAssignments, loggedOutUserExperimentUid } = pageProps;

  // Set dayjs locale globally
  React.useEffect(() => {
    if (TRANSLATIONS_ENABLED) {
      getDayjsLocale(locale).then(dayjs.locale);
    }
  }, [locale]);

  React.useEffect(() => {
    if (identified) {
      return;
    }

    identify();
    setIdentified(true);
  }, [identify, identified]);

  React.useEffect(() => {
    if (analyticsPending) {
      return;
    }

    if (!!window.Intercom) {
      try {
        window.Intercom("boot", {
          app_id: "d85jnqmd",
          hide_default_launcher: true,
        });
      } catch (err) {
        console.log("UnexpectedIntercomError", err);
      }
    }
  }, [analyticsPending]);

  return (
    <AttributionProvider>
      <TokenProvider isTest={isTest} isDevelopment={isDevelopment}>
        <ExperimentsServiceProvider
          initialExperimentAssignments={experimentAssignments}
          experimentsApiKey={EXPERIMENTS_API_KEY}
          experimentsServiceUrl={EXPERIMENTS_SERVICE_URL}
          isTest={isTest}
          isProduction={isProduction}
          osEnv={OUTSCHOOL_ENV}
          timeoutMs={EXPERIMENT_ASSIGNMENTS_MAX_REQ_TIME_MS}
          isExperimentOverridesEnabled={isExperimentOverridesEnabled}
          eventSource={EVENT_SOURCE.SSR}
          loggedOutUserExperimentUid={loggedOutUserExperimentUid}
        >
          <SsrApolloProvider>
            <LocalizationProvider userSelectedLocale={locale}>
              <NavigationProvider
                linkComponent={Link}
                useNavigation={useSSRNavigation}
              >
                <VideoPlayerProvider videoPlayerComponent={VideoPlayer}>
                  <LoginFlowProvider shouldNavigate={false}>
                    <LegacyThemeProvider
                      theme={(baseTheme: Theme) => ({
                        ...baseTheme,
                        components: {
                          ...baseTheme.components,
                          MuiImage: {
                            BaseComponent: SsrImage,
                          },
                        },
                      })}
                    >
                      <PageAlertsProvider>
                        <TopNoticeProvider>
                          <MobileSearchBarProvider>
                            <AppWithTopNoticeProvider
                              Component={Component}
                              pageProps={pageProps}
                            />
                          </MobileSearchBarProvider>
                        </TopNoticeProvider>
                      </PageAlertsProvider>
                    </LegacyThemeProvider>
                  </LoginFlowProvider>
                </VideoPlayerProvider>
              </NavigationProvider>
            </LocalizationProvider>
          </SsrApolloProvider>
        </ExperimentsServiceProvider>
      </TokenProvider>
    </AttributionProvider>
  );
}

const appToExport = TRANSLATIONS_ENABLED
  ? appWithTranslation(App, nextI18nextConfig as any)
  : App;

const eventNameMap = {
  "Next.js-hydration": "hydration",
  TTFB: "ttfb",
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  const label = eventNameMap[metric.name];

  if (!label) {
    return;
  }

  measurePerformance(label, metric);
}

export default appToExport;
