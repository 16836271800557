import { getReferrerAttribution } from "@outschool/attribution";
import { useLookupIP } from "@outschool/iplookup-client";
import { captureError } from "@outschool/platform";
import { signUpPath } from "@outschool/routes";
import { guessBrowserTimeZone } from "@outschool/time";
import { useAnalytics, useTrackEvent } from "@outschool/ui-analytics";
import {
  ContinueWithGoogleOneTap,
  useLoginWithGoogleOneTapMutationV2,
  useRefreshSessionToken,
  useTokenContext,
} from "@outschool/ui-auth";
import { useNavigation } from "@outschool/ui-utils";
import { useRouter } from "next/router";
import React from "react";

import { GOOGLE_CLIENT_ID, isProduction } from "../Env";

function useLoginWithGoogleOneTap({
  onSuccess,
  onError,
}: {
  onSuccess: (isNewUser: boolean) => void;
  onError: (error: Error) => void;
}) {
  const loginWithGoogle = useLoginWithGoogleOneTapMutationV2();
  const analytics = useAnalytics();
  const { setTokens } = useTokenContext();
  const { ipInfoLoaded, isInGDPR } = useLookupIP();
  const refreshToken = useRefreshSessionToken();

  return React.useCallback(
    async (token: string) => {
      const createOptions = {
        attribution: await analytics.attribution(),
        browserTimeZone: guessBrowserTimeZone(),
        isGiftCardSignup: false,
        isLeader: false,
        subscribe: ipInfoLoaded ? !isInGDPR : false,
      };

      const anonymousId = await analytics.anonymousId();
      const osRef = {
        ...getReferrerAttribution(),
        anonymousId,
      };

      try {
        const {
          data: { loginOrCreateAccountWithGoogleV2: response },
        } = await loginWithGoogle({
          variables: {
            createOptions,
            osRef,
            idToken: token,
          },
        });

        if (response === undefined) {
          onError(new Error("empty response"));
          return;
        }

        if (response.__typename === "LoginError") {
          onError(new Error("login error"));
        } else {
          const {
            isNewUser,
            authentication: { sessionToken },
          } = response;

          setTokens(sessionToken);
          await refreshToken();
          onSuccess(isNewUser);
        }
      } catch (e) {
        onError(e);
      }
    },
    [
      analytics,
      loginWithGoogle,
      onSuccess,
      onError,
      refreshToken,
      setTokens,
      isInGDPR,
      ipInfoLoaded,
    ]
  );
}

const SignupWithGoogleOneTap = () => {
  const router = useRouter();
  const navigate = useNavigation();
  const trackEvent = useTrackEvent();

  const loginWithGoogle = useLoginWithGoogleOneTap({
    onSuccess: isNewUser => {
      isNewUser
        ? trackEvent("signup-by-google-onetap")
        : trackEvent("signin-by-google-onetap");
      router.reload();
    },
    onError: error => {
      console.log(error);
      captureError(error);
      navigate(signUpPath(), { hardNav: true });
    },
  });

  return (
    <ContinueWithGoogleOneTap
      clientId={GOOGLE_CLIENT_ID as string}
      loginWithGoogle={loginWithGoogle}
      shouldHide={() => false}
      onLoad={() => {}}
      onPrompt={notification => {
        if (!isProduction) {
          console.debug(notification);
          console.debug(
            // eslint-disable-next-line i18next/no-literal-string
            "onetap: notdisplayed: ",
            notification.getNotDisplayedReason()
          );
          // eslint-disable-next-line i18next/no-literal-string
          console.debug("onetap: skipped: ", notification.getSkippedReason());
          console.debug(
            // eslint-disable-next-line i18next/no-literal-string
            "onetap: dismissed: ",
            notification.getDismissedReason()
          );
        }
        trackEvent("one-tap-prompt", {
          isDisplayed: notification.isDisplayed(),
          notDisplayReason: notification.getNotDisplayedReason(),
          skippedReason: notification.getSkippedReason(),
          getDismissedReason: notification.getDismissedReason(),
        });
      }}
    />
  );
};

export default SignupWithGoogleOneTap;
